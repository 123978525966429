#resultTrue {
    font-size: 9rem;
    color: #52B18C;
}

#resultFalse {
    font-size: 9rem;
    color: red;
}

#idIconUser {
    float: right;
    margin-left: 71px;
    margin-top: -52px;
    position: absolute;
    color: #868686;
    z-index: 2;
}
#idIconUser2 {
    float: right;
    margin-left: 71px;
    margin-top: -29px;
    position: absolute;
    color: #868686;
    z-index: 2;
}