.customClass>.MuiIconButton-edgeEnd {
    display: none !important;
}

.MuiAvatar-root {
    position: absolute !important;
    right: 20px !important;
}

.alignRigthIconMessage>.MuiTab-wrapper {
    text-transform: capitalize !important;
    font-size: 12pt !important;
    font-family: 'Montserrat-Semibold' !important;
    color: black;
}

.responsive-title-date-notif {
    margin-bottom: 15px;
    text-align: right;
}

@media (min-height:300px) {
    .responsive-title-date-notif {
        margin-bottom: 15px;
        text-align: left;
    }
}

@media (min-height:1000px) {
    .responsive-title-date-notif {
        margin-bottom: 15px;
        text-align: right;
    }
}

.height-notif {
    max-height: 509px;
}

@media (min-height:300px) {
    .height-notif {
        max-height: 509px;
    }
}

@media (min-height:667px) and (min-width:375px) {
    .height-notif {
        max-height: 565px;
    }
}

@media (min-height:667px) and (min-width:1360px) {
    .height-notif {
        max-height: 617px;
    }
}

@media (min-height:736px) {
    .height-notif {
        max-height: 630px;
    }
}

@media (min-height:768px) and (min-width:1360px) {
    .height-notif {
        max-height: 670px;
    }
}

@media (min-height:812px) {
    .height-notif {
        max-height: 700px;
    }
}

@media (min-height:800px) {
    .height-notif {
        max-height: 760px;
    }
}

@media (min-height:900px) {
    .height-notif {
        max-height: 863px;
    }
}