.all100x {
    width: 100% !important;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    border-radius: 10px;
    padding: 6px 12px;
    cursor: pointer;
    background: #26559C !important;
    color: white !important;
    font-size: 17px !important;
    border-radius: 11.5px !important;
    min-height: 45px;
    font-family: 'Caviar-Dreams-Bold';
    padding-top: 9px;
}

.scrolling-card {
    overflow-y: scroll;
    scroll-behavior: auto;
    max-height: 1500px;
}

.height-profile-2{
    height: calc(100% - 40%);
}

.width-profile-2{
    margin-left: auto;
    margin-right: auto;
    width: 200px;
}

@media screen and (min-width: 320px) {
    .height-profile-2{
        height: calc(100% - 70%);
    }
    
}
@media screen and (min-width: 375px) {
    .height-profile-2{
        height: calc(100% - 63%);
    }
    
}

@media screen and (min-width: 414px) {
    .height-profile-2{
        height: calc(100% - 59%);
    }
    
}

@media screen and (min-width: 600px) {
    .height-profile-2{
        height: calc(100% - 46%);
    }
    
}
@media screen and (min-width: 640px) {
    .height-profile-2{
        height: calc(100% - 31%);
    }
    
}
@media screen and (min-width: 667px) {
    .height-profile-2{
        height: calc(100% - 56%);
    }
}
@media screen and (min-width: 768px) {
    .height-profile-2{
        height: calc(100% - 48%);
    }
}
@media screen and (min-width: 1024px) {
    .height-profile-2{
        height: calc(100% - 68%);
    }
}
@media screen and (min-width: 1360px) {
    .height-profile-2{
        height: calc(100% - 56%);
    }
}
@media screen and (min-width: 1366px) {
    .height-profile-2{
        height: calc(100% - 49%);
    }
}