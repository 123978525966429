.hideButton{
    display: none !important;
}
.showButton{
    display: block !important;
}
.widthCheck2{
    width: 100%;
}
#texAreaNotification{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 15px;
}
.widthLogoCheck{
    width: 20%;
}
.justify{
    text-align: justify;
    text-justify: inter-word;
    font-family: 'Montserrat-Regular';
}
#idDialogWelcome {
    padding-bottom: 0px;
}
#idDialogWelcome > .MuiTypography-root{
    text-align: left;
    font-family:'Montserrat-SemiBold' !important;
    padding-bottom: 0px;
}
.heightDialogWelcome{
    height: 473px;
    width: 538px;
}
#idDialogWelcomessss{
    font-family:'Montserrat-SemiBold' !important;
}
#idDialogWelcomessss > h2{
    font-family:'Montserrat-SemiBold' !important;
}
.itemRedStreaming{
    text-align: center ;
    margin-bottom: 15px !important;
    border-radius: 11px;
}
#alert-dialog-change-password > .MuiTypography-root{
    font-family: 'Montserrat-SemiBold' !important;
}
.marginInputText{
    margin: 8px !important;
}

#id-dialog-notification > .MuiTypography-root{
    font-family: 'Montserrat-SemiBold' !important;
}

.closePromotion{
    position: absolute !important;
    top: -1px !important;
    right: 0px !important;
}

