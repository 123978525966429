.TabDesign {
    height: 48px !important;
    background: transparent !important;
    border-radius: 10px 10px 0px 0px !important;
}

.tabHeader {
    border-radius: 10px 10px 0px 0px !important;
    width: 60% !important;
}

@media (min-width:300px) {
    .tabHeader {
        border-radius: 10px 10px 0px 0px !important;
        width: 90% !important;
    }
}

@media (min-width:1000px) {
    .tabHeader {
        border-radius: 10px 10px 0px 0px !important;
        width: 60% !important;
    }
}

.borderTabContext {
    border-radius: 10px 10px 0px 0px !important;
}

.divTabContext>.MuiPaper-root>.MuiButtonBase-root {
    max-width: unset;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 0px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 47px !important;
}

.MuiAccordionSummary-content {
    margin: 0px !important;
}

.divTabContext>.MuiAppBar-positionStatic {
    margin-left: 12px !important;
}