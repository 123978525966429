.webviewerCustom {
    overflow-y: hidden !important;
    width: 100% !important;
}

.body2 {
    position: relative;
}

.popup {
    position: absolute;
    max-width: 800px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#spopup {
    background: #f3f3f3;
    border-radius: 9px;
    -moz-border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-box-shadow: inset 0 0 3px #333;
    -webkit-box-shadow: inset 0 0 3px #333;
    box-shadow: inner 0 0 3px #333;
    padding: 12px 14px 12px 14px;
    width: 300px;
    position: fixed;
    bottom: 13px;
    right: 2px;
    z-index: 90;
}

#spopupV {
    background: #f3f3f3;
    border-radius: 9px;
    -moz-border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-box-shadow: inset 0 0 3px #333;
    -webkit-box-shadow: inset 0 0 3px #333;
    box-shadow: inner 0 0 3px #333;
    padding: 12px 14px 12px 14px;
    width: 360px;
    position: fixed;
    bottom: 13px;
    left: 66px;
    z-index: 90;
}

#idmenuAudio {
    width: 65px;
    height: 50px;
    background: #f3f3f3;
    border-radius: 9px;
    -moz-box-shadow: inset 0 0 3px #333;
    -webkit-box-shadow: inset 0 0 3px #333;
    box-shadow: inner 0 0 3px #333;
    position: fixed;
    top: 215px;
    right: 24px;
    z-index: 90;
}

#idmenuVideo {
    width: 65px;
    height: 50px;
    background: transparent;
    border-radius: 9px;
    -moz-box-shadow: inset 0 0 3px #333;
    -webkit-box-shadow: inset 0 0 3px #333;
    box-shadow: inner 0 0 3px #333;
    position: fixed;
    top: 415px;
    right: 24px;
    z-index: 90;
}

audio::-webkit-media-controls-enclosure {
    overflow: hidden;
}

.style-logo-visor-pdf {
    display: block;
}

.back-button-visor-pdf {
    display: none;
}

.style-back-button {
    float: right;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    /*height: 82px;*/
}

.back-button-visor-pdf-2 {
    float: right;
    display: flex;
    margin-top: 5px;
}

@media (min-width:300px) {
    .style-logo-visor-pdf {
        display: none;
    }
    .back-button-visor-pdf {
        display: block;
    }
    .back-button-visor-pdf-2 {
        display: none;
    }
}

@media (min-width:1000px) {
    .style-logo-visor-pdf {
        display: block;
    }
    .back-button-visor-pdf {
        display: none;
    }
    .back-button-visor-pdf-2 {
        float: right;
        display: flex;
        margin-top: 5px;
    }
}