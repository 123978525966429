body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

@font-face {
  font-family: 'Caviar-Dreams';
  src: url("./font/CaviarDreams.ttf");
}

@font-face {
  font-family: 'Caviar-Dreams-Bold';
  src: url("./font/caviarDreams/CaviarDreams_Bold.ttf");
}
@font-face{
  font-family: 'Montserrat-Black';
  src: url("./font/monserrat/Montserrat-Black.ttf");
}
@font-face{
  font-family: 'Montserrat-Bold';
  src: url("./font/monserrat/Montserrat-Bold.ttf");
}
@font-face{
  font-family: 'Montserrat-ExtraBold';
  src: url("./font/monserrat/Montserrat-ExtraBold.ttf");
}
@font-face{
  font-family: 'Montserrat-Medium';
  src: url("./font/monserrat/Montserrat-Medium.ttf");
}
@font-face{
  font-family: 'Montserrat-Medium-Italic';
  src: url("./font/monserrat/Montserrat-MediumItalic.ttf");
}
@font-face{
  font-family: 'Montserrat-Regular';
  src: url("./font/monserrat/Montserrat-Regular.ttf");
}
@font-face{
  font-family: 'Montserrat-SemiBold';
  src: url("./font/monserrat/Montserrat-SemiBold.ttf"); 
}
