#drawer>.MuiPaper-root {
    background-color: #263445;
}

.colorIcon {
    color: white;
}

.nameItem {
    color: white;
}

.makeStyles-menuButton-4 {
    margin-right: 0px !important;
}

.hideDiv {
    display: none !important;
}

.showDiv {
    display: block !important;
}

#idBackClassroom {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}

.iconStudent {
    width: 180px !important;
}

.iconStudent2 {
    width: 180px !important;
    height: 120px !important;
    border-radius: 5px;
    object-fit: contain;
}

.cardStudent {
    text-align: center !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

body {
    height: 100%;
    margin: 0;
    background-color: #E6E6E6 !important;
    overflow: hidden !important;
}

.style-width-icon-action-toolbar {
    width: 40%;
    cursor: pointer;
}

.iconButtonToolbar {
    text-align: center;
    display: flex !important;
    align-items: center;
    float: right;
    padding: 0px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    height: 47px;
}

.styleSectionItem {
    margin-bottom: 0px !important;
    font-family: 'Montserrat-Regular' !important;
    cursor: pointer !important;
}

.widthLogoStoDomingo-AppBar {
    width: 16%;
}

.appBar-responsive {
    display: block;
}

.height-top-toolbar {
    padding-top: 5px;
    height: 57px;
}

.style-align-responsive-button-search-section {
    text-align: left;
}

@media (min-width: 300px) {
    .widthLogoStoDomingo2 {
        width: 77%;
        margin-left: 0px;
    }
    .widthLogoStoDomingo-AppBar {
        width: 16%;
        display: none;
    }
    .appBar-responsive {
        display: none;
    }
    .height-top-toolbar {
        padding-top: 5px;
        height: 57px;
    }
    .style-align-responsive-button-search-section {
        text-align: center;
    }
}

@media (min-width: 1000px) {
    .widthLogoStoDomingo2 {
        width: 77%;
        margin-left: 0px;
    }
    .widthLogoStoDomingo-AppBar {
        width: 14%;
        display: block;
    }
    .appBar-responsive {
        display: block;
    }
    .height-top-toolbar {
        padding-top: 11px;
        height: 68px;
    }
    .style-align-responsive-button-search-section {
        text-align: left;
    }
}