.widthLogoStoDomingo {
    width: 20%;
}

@media (min-width: 300px) {
    .widthLogoStoDomingo {
        width: 60%;
        /* margin-left: 30px; */
    }
}

@media (min-width: 1024px) {
    .widthLogoStoDomingo {
        width: 20%;
        /* margin-left: 30px; */
    }
}

.marginTopField {
    margin-top: 10px;
    /*
    padding: 10px;*/
}

#card1 {
    margin-left: auto;
    margin-right: auto;
    border-radius: 11.5px !important;
}

.style-width-card-1 {
    width: 45%;
}

#card2 {
    margin-left: auto;
    margin-right: auto;
    border-radius: 11.5px !important;
}

.style-mTop-register {
    margin-top: 33px;
}

.style-mTop-welcome-card {
    margin-top: 128px;
}

.style-align-responsive-btn-create-user {
    text-align: center;
}

.style-txt-password-security {
    font-family: 'Montserrat-Regular';
    color: #1A1A1A;
    font-size: 11pt;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;
}

.style-width-txt-ps-security {
    width: 76%;
}

@media (min-width:300px) {
    .style-width-card-1 {
        width: 86%;
    }
    .style-mTop-register {
        margin-top: 33px;
    }
    .style-align-responsive-btn-create-user {
        text-align: right;
    }
    .style-mTop-welcome-card {
        margin-top: 128px;
    }
    .style-width-txt-ps-security {
        width: 76%;
    }
}

@media (min-width:1000px) {
    .style-width-card-1 {
        width: 45%;
    }
    .style-mTop-register {
        margin-top: 65px;
    }
    .style-align-responsive-btn-create-user {
        text-align: center;
    }
    .style-mTop-welcome-card {
        margin-top: 156px;
    }
    .style-width-txt-ps-security {
        width: 82%;
    }
}

#cardWelcome {
    /*width: 706px;*/
    margin-left: auto;
    margin-right: auto;
    border-radius: 11.5px !important;
}

#btnNext {
    width: 550px !important;
    margin-left: auto;
    margin-right: auto;
}