.customIcon {
    float: right;
    margin-left: -24px;
    margin-top: 20px;
    position: absolute;
    color: #868686;
    z-index: 2;
}

.customIconGlass {
    float: right;
    margin-left: -36px;
    margin-top: 14px;
    position: absolute;
    color: #6A6B6B;
    z-index: 2;
    font-size: 2rem !important;
}

.styleWidthButton {
    height: 46px !important;
    width: 222px !important;
}

.styleWidthButton:hover {
    color: white !important;
    background-color: #5177B0 !important;
}

.styleWidthButtonCancel {
    height: 46px !important;
    width: 222px !important;
    color: #26559C !important;
    background-color: white !important;
    border: 3px solid #26559C !important;
}

#idBtnSearch {
    height: 46px;
    border-radius: 20.25px;
    color: white;
    background-color: #26559C;
    text-transform: capitalize !important;
    box-shadow: unset !important;
}

#idBtnSearch>.MuiButton-label {
    font-family: 'Caviar-Dreams-Bold' !important;
}

#btnClear {
    border-radius: 25px;
    color: black;
    box-shadow: unset !important;
    font-family: 'Gotham-Book' !important;
    font-weight: bold !important;
}

#idBtnCustom {
    border-radius: 25px !important;
    border: 2px solid #E6427A !important;
    background-color: #E6427A !important;
    color: white !important;
    box-shadow: unset !important;
    width: 230px !important;
    font-family: 'Gotham-Book' !important;
    font-weight: bold !important;
}

#idBtnAccess {
    border-radius: 15px !important;
    border: 2px solid #26559C !important;
    background-color: transparent !important;
    color: #26559C !important;
    box-shadow: unset !important;
    font-family: 'Gotham-Book' !important;
    font-weight: bold !important;
}

#idBtnAccess:hover {
    color: white !important;
    background-color: #5177B0 !important;
}

#idBtnLogin {
    border-radius: 20.25px !important;
    border: 2px solid #26559C !important;
    background-color: #26559C !important;
    color: #ffffff !important;
    box-shadow: unset !important;
    font-family: 'Gotham-Book' !important;
    font-weight: bold !important;
}

#idBtnLogin:hover {
    background-color: #5177B0 !important;
}

#idBtnTeacher {
    border-radius: 20.25px !important;
    border: 0px solid #26559C !important;
    background-color: #26559C !important;
    color: #ffffff !important;
    box-shadow: unset !important;
    font-family: 'Gotham-Book' !important;
    font-weight: bold !important;
}

#idBtnStudent {
    border-radius: 20.25px !important;
    border: 0px solid #26559C !important;
    background-color: #26559C !important;
    color: #ffffff !important;
    box-shadow: unset !important;
    font-family: 'Gotham-Book' !important;
    font-weight: bold !important;
}

#idBtnStudent:hover {
    background-color: #5177B0 !important;
}

#idBtnTeacher:hover {
    background-color: #5177B0 !important;
}

#idBtnStudent>.MuiButton-label {
    font-family: 'Caviar-Dreams-Bold' !important;
}

#idBtnTeacher>.MuiButton-label {
    font-family: 'Caviar-Dreams-Bold' !important;
}

#btnReturn {
    width: 100%;
    height: 46px;
    border-radius: 5px;
    color: #26559C;
    background-color: transparent;
    border: 1px solid #26559C;
    box-shadow: unset !important;
    font-family: 'Gotham-Book' !important;
    font-weight: bold !important;
}

.changeWithHeight {
    width: 36px !important;
    height: 36px !important;
    box-shadow: unset !important;
}

#btnReturnRegister {
    /*width: auto;*/
    height: 46px;
    border-radius: 20.25px;
    color: #26559C;
    background-color: transparent;
    text-transform: capitalize !important;
    border: 4px solid #26559C;
    box-shadow: unset !important;
    font-family: 'Gotham-Book' !important;
}

#btnReturnRegister:hover {
    color: white;
    background-color: #26559C;
}

#btnReturnRegister>.MuiButton-label {
    font-family: 'Caviar-Dreams-Bold' !important;
}

#idBtnContinue1 {
    /*width: auto;*/
    height: 46px;
    border-radius: 20.25px !important;
    border: 4px solid #26559C !important;
    color: #26559C !important;
    background-color: white !important;
    text-transform: capitalize !important;
    box-shadow: unset !important;
}

.style-width-responsive-button-register {
    width: 200px;
}

.style-width-responsive-button-continue-1 {
    width: 200px;
}

@media (min-width:300px) {
    .style-width-responsive-button-register {
        width: auto;
    }
    .style-width-responsive-button-continue-1 {
        width: auto;
    }
}

@media (min-width:1000px) {
    .style-width-responsive-button-register {
        width: 80%;
    }
    .style-width-responsive-button-continue-1 {
        width: 80%;
    }
}

#idBtnContinue1:hover {
    color: white !important;
    background-color: #26559C !important;
}

#idBtnContinue1>.MuiButton-label {
    font-family: 'Caviar-Dreams-Bold' !important;
}

#idBtnCreateUser {
    /*width: auto;*/
    min-height: 46px;
    border-radius: 20.25px !important;
    border: 4px solid #26559C;
    color: #26559C !important;
    background-color: white !important;
    text-transform: capitalize !important;
    box-shadow: unset !important;
    font-family: 'Gotham-Book' !important;
}

#idBtnCreateUser:hover {
    color: white !important;
    background-color: #26559C !important;
}

#idBtnCreateUser>.MuiButton-label {
    font-family: 'Caviar-Dreams-Bold' !important;
}

#idBtnIngresar {
    /*width: 200px;*/
    height: 46px;
    border-radius: 20.25px !important;
    border: 4px solid #26559C;
    color: #26559C !important;
    background-color: white !important;
    text-transform: capitalize !important;
    box-shadow: unset !important;
    font-family: 'Gotham-Book' !important;
}

#idBtnIngresar:hover {
    color: white !important;
    background-color: #26559C !important;
}

#idBtnIngresar>.MuiButton-label {
    font-family: 'Caviar-Dreams-Bold' !important;
}

#idBtnAccess {
    /*width: 180px;*/
    height: 46px;
    border-radius: 20.25px;
    color: white !important;
    background-color: #26559C !important;
    text-transform: capitalize !important;
    box-shadow: unset !important;
    font-family: 'Gotham-Book' !important;
    font-size: 15pt;
}

#idBtnAccess>.MuiButton-label {
    font-family: 'Caviar-Dreams-Bold' !important;
}

#idbuttonAdd {
    margin: 0px;
    width: 40px !important;
    height: 40px !important;
    background: green !important;
}

#idButtonOut {
    width: 90%;
}

.paddingButtonCreateSecction {
    margin-left: 48px !important;
}

.custom-button-design {
    border: 1px solid #ccc;
    display: inline-block;
    border-radius: 10px;
    padding: 6px 12px;
    cursor: pointer;
    background: #54A5E5 !important;
    color: white !important;
    font-size: 17px !important;
    border-radius: 11.5px !important;
    min-height: 45px;
    font-family: 'Caviar-Dreams-Bold';
    padding-top: 9px;
}