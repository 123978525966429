.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}
.styleNameCollection {
    color: #26559C;
    font-family: 'Montserrat-Semibold';
    text-align: left;
}

/***media querys general ***/

.height-containerBooks {
    margin-top: 20px;
    max-height: 392px;
}

.contentDivImgBook {
    height: auto;/*264px;*/
}

.img-portada-book {
    object-fit: contain;
    max-width: 72px;
}

.mtop-divider {
    padding-top: 20px;
}

.heightContentThemsChapter {
    max-height: 550px;
}

.drawerHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.drawerHeader-1 {
    min-height: 64px;
}

.height-themes {
    max-height: 475px;
}

.widthLogoStoDomingo-visor {
    width: 37%;
}

.height-viewer {
    height: 91vh;
}

.height-grid-profile {
    max-height: 500px;
}

.height-chapter-responsive {
    max-height: 364px;
}

.height-only-section {
    max-height: 350px;
}
.styleScreenPopup{
    width: 260px;
    height: 205px;
}

.centerVertical-hijo{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
    margin: auto;
}
.width-responsive-section{
    width: 500px;
}

.padding-input-txt{
    padding: 5px !important;
}
.margin-button-style{
    margin: 5px !important;
}
.style-Left-screen{
    left: 9%;
}
.width-p-password{
    width: 213px;
}
/* IPHONE 5/SE **/
@media screen and (min-width:320px) {
    .img-portada-book {
        object-fit: contain;
        max-width: 70px;
    }
    .width-p-password{
        width: 213px;
    }
}
@media screen and (min-width:1000px) {
    .img-portada-book {
        object-fit: contain;
        max-width: 171px;
    }
    .width-p-password{
        width: 441px;
    }
}
@media (min-height:568px) and (min-width:320px) {
    .height-containerBooks {
        margin-top: 0px;
        max-height: 340px;
    }
    .contentDivImgBook {
        height: auto;/*215px;*/
    }    
    .mtop-divider {
        padding-top: 0px;
    }
    .heightContentThemsChapter {
        max-height: 452px;
    }
    .drawerHeader-1 {
        min-height: 32px;
    }
    .height-themes {
        max-height: 200px;
    }
    .height-viewer {
        height: 81vh;
    }
    .height-grid-profile {
        max-height: 500px;
    }
    .height-chapter-responsive {
        max-height: 364px;
    }
    .height-only-section {
        max-height: 350px;
    }
    .styleScreenPopup{
        width: 200px;
        height: 205px;
    }
    #idBtnAccess {
        width: 80%;
    }
    .width-responsive-section{
        width: 100%;
    }
    .style-Left-screen{
        left: 9%;
    }
}


/** Moto G4  ***/

@media (min-height:640px) and (min-width:360px) {
    .heightContentThemsChapter {
        max-height: 517px;
    }
    .height-viewer {
        height: 87vh;
    }
    .height-grid-profile {
        max-height: 484px;
    }
    .height-chapter-responsive {
        max-height: 364px;
    }
    .height-only-section {
        max-height: 280px;
    }
    .width-responsive-section{
        width: 100%;
    }
    .style-Left-screen{
        left: 9%;
    }
}


/***iphone 6/7/8 **/

@media (min-height:667px) and (min-width:375px) {
    .height-containerBooks {
        margin-top: 20px;
        max-height: 419px;
    }
    .contentDivImgBook {
        height: auto;/*202px;*/
    }
    .img-portada-book {
        object-fit: contain;
        max-width: 98px;
    }
    .mtop-divider {
        padding-top: 0px;
    }
    .heightContentThemsChapter {
        max-height: 550px;
    }
    .drawerHeader-1 {
        min-height: 32px;
    }
    .height-themes {
        max-height: 200px;
    }
    .height-viewer {
        height: 88vh;
    }
    .height-grid-profile {
        max-height: 512px;
    }
    .height-chapter-responsive {
        max-height: 364px;
    }
    .height-only-section {
        max-height: 353px;
    }
    .width-responsive-section{
        width: 100%;
    }
    .style-Left-screen{
        left: 9%;
    }
}

@media (min-height:667px) and (min-width:1360px) {
    .height-containerBooks {
        margin-top: 20px;
        max-height: 535px;
    }
    .contentDivImgBook {
        height: auto;/*287px;*/
    }
    .img-portada-book {
        object-fit: contain;
        max-width: 171px;
    }
    .mtop-divider {
        padding-top: 20px;
    }
    .heightContentThemsChapter {
        max-height: 560px;
    }
    .drawerHeader-1 {
        min-height: 64px;
    }
    .height-themes {
        max-height: 521px;
    }
    .height-viewer {
        height: 92vh;
    }
    .height-grid-profile {
        max-height: 500px;
    }
    .height-chapter-responsive {
        max-height: 364px;
    }
    .height-only-section {
        max-height: 500px;
    }
    .styleScreenPopup{
        width: 400px;
        height: 169px;
    }
    #idBtnAccess {
        width: 180px;
    }
    .width-responsive-section{
        width: 500px;
    }
    .style-Left-screen{
        left: 4%;
    }
}
@media screen  and (min-width:412px) {
    .height-containerBooks {
        margin-top: 0px;
        max-height: 535px;
      }
}


/** iPhone 6/7/8 plus  **/

@media (min-height:736px) and (min-width:414px) {
    .height-containerBooks {
        margin-top: 20px;
        max-height: 478px;
    }
    .heightContentThemsChapter {
        max-height: 610px;
    }
    .height-viewer {
        height: 89vh;
    }
    .height-grid-profile {
        max-height: 578px;
    }
    .height-chapter-responsive {
        max-height: 364px;
    }
    .height-only-section {
        max-height: 350px;
    }
    .img-portada-book {
        object-fit: contain;
        max-width: 130px;
    }
    .styleScreenPopup{
        width: 320px;
        height: 169px;
    }
    #idBtnAccess {
        width: 80%;
    }
    .width-responsive-section{
        width: 100%;
    }
    .height-themes {
        max-height: 200px;
    }
    .style-Left-screen{
        left: 9%;
    }
    
}
@media screen  and (min-width:1000px) {
    .height-containerBooks {
        margin-top: 0px;
        max-height: 535px;
      }
}
@media (min-height:768px) and (min-width:1360px) {
    .img-portada-book {
        object-fit: contain;
        max-width: 130px;
    }
    .styleScreenPopup{
        width: 400px;
        height: 169px;
    }
    #idBtnAccess {
        width: 180px;
    }
    .width-responsive-section{
        width: 500px;
    }
    .height-themes {
        max-height: 565px;
    }
    .style-Left-screen{
        left: 4%;
    }
}

