.widthCustom {
    width: 230px;
}

.widthCustom2 {
    width: 100%;
}

#searchDNI {
    text-align: left;
    color: #868686 !important;
    background: white;
    border-radius: 18px;
}

.textNotification {
    text-align: left !important;
    color: #868686 !important;
    background: white !important;
}

#inputLogin {
    color: #868686 !important;
    text-align: center !important;
}

.MuiInputBase-root {
    font-family: "Montserrat-Medium" !important;
}

.changeTypeTextPassword>.MuiFormControl-root>.MuiFormLabel-root {
    font-family: "Montserrat-Medium" !important;
}

.MuiTypography-body1 {
    font-family: "Montserrat-Medium" !important;
}

#idPassLogin-standard-adornment-password {
    width: 157px;
}

.txtColorError {
    color: #F44336 !important;
}

.txtColorError:focus {
    color: #F44336 !important;
}

.divCenterPassword {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 213px !important;
}

.divCenterPassword2 {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
    text-align: left !important;
}

.checkcustomTerms>.MuiFormControlLabel-root {
    margin-left: 5px !important;
}

.checkResponsiveMargin {
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    width: 91%;
}

@media (min-width:300px) {
    .checkResponsiveMargin {
        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;
        width: 110%;
    }
}

@media (min-width:1000px) {
    .checkResponsiveMargin {
        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;
        width: 91%;
    }
}