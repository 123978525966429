.widthLogoStoDom {
    width: 100%;
    margin-left: 30px;
}

.widthLogoStoDomHome {
    width: 100%;
}

.buttonHomeAccess {
    padding-right: 15px;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.styleAlignResponsive {
    text-align: right;
}

#iconsGrid {
    text-align: center;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

#gridSlider {
    padding: unset !important
}

.widthLogoIcons {
    width: 14%;
    padding: 0px;
    cursor: pointer
}

.thumbs-wrapper {
    display: none;
}

.carousel .slide img {
    width: 90%;
}

.txtGroupEditorial {
    margin-right: 50px;
    margin-bottom: 0px;
}

@media (min-width: 300px) {
    .widthLogoStoDom {
        width: 30%;
        margin-left: 30px;
    }
    .widthLogoStoDomHome {
        width: 60%;
    }
    .styleAlignResponsive {
        text-align: center;
    }
    .view-desktop {
        display: block;
    }
    .view-responsive {
        display: none;
    }
    .txtGroupEditorial {
        margin-right: unset;
        margin-bottom: unset;
    }
}

@media (min-width: 700px) {
    .widthLogoStoDom {
        width: 30%;
    }
    .widthLogoStoDomHome {
        width: 30%;
    }
    .styleAlignResponsive {
        text-align: center;
    }
    .view-desktop {
        display: none;
    }
    .view-responsive {
        display: block;
    }
    .txtGroupEditorial {
        margin-right: 50px;
        margin-bottom: 0px;
    }
}

@media (min-width: 1024px) {
    .widthLogoStoDom {
        width: 30%;
    }
    .widthLogoStoDomHome {
        width: 30%;
    }
    .styleAlignResponsive {
        text-align: right;
    }
}

.carousel-status {
    display: none;
}

body {
    background-color: white !important;
}