.MuiPaper-elevation4 {
    box-shadow: unset !important;
}

.MuiAppBar-colorPrimary {
    color: #000000 !important;
    background-color: #fff !important;
}

.heightCustomLogin {
    height: 360px;
}

#wrapper {
    height: 100%;
}

@media (min-height: 300px) {
    .heightCustomLogin {
        height: 210px;
    }
}

@media (min-height: 320px) {
    .heightCustomLogin {
        height: 215px;
    }
}

@media (min-height: 340px) {
    .heightCustomLogin {
        height: 220px;
    }
}

@media (min-height: 360px) {
    .heightCustomLogin {
        height: 225px;
    }
}

@media (min-height: 380px) {
    .heightCustomLogin {
        height: 230px;
    }
}

@media (min-height: 400px) {
    .heightCustomLogin {
        height: 235px;
    }
}

@media (min-height: 420px) {
    .heightCustomLogin {
        height: 240px;
    }
}

@media (min-height: 440px) {
    .heightCustomLogin {
        height: 245px;
    }
}

@media (min-height: 460px) {
    .heightCustomLogin {
        height: 250px;
    }
}

@media (min-height: 480px) {
    .heightCustomLogin {
        height: 255px;
    }
}

@media (min-height: 500px) {
    .heightCustomLogin {
        height: 260px;
    }
}

@media (min-height: 520px) {
    .heightCustomLogin {
        height: 265px;
    }
}

@media (min-height: 540px) {
    .heightCustomLogin {
        height: 270px;
    }
}

@media (min-height: 560px) {
    .heightCustomLogin {
        height: 275px;
    }
}

@media (min-height: 580px) {
    .heightCustomLogin {
        height: 280px;
    }
}

@media (min-height: 600px) {
    .heightCustomLogin {
        height: 300px;
    }
}

@media (min-height: 620px) {
    .heightCustomLogin {
        height: 305px;
    }
}

@media (min-height: 640px) {
    .heightCustomLogin {
        height: 310px;
    }
}

@media (min-height: 660px) {
    .heightCustomLogin {
        height: 315px;
    }
}

@media (min-height: 680px) {
    .heightCustomLogin {
        height: 320px;
    }
}

@media (min-height: 700px) {
    .heightCustomLogin {
        height: 325px;
    }
}

@media (min-height: 720px) {
    .heightCustomLogin {
        height: 330px;
    }
}

@media (min-height: 740px) {
    .heightCustomLogin {
        height: 335px;
    }
}

@media (min-height: 760px) {
    .heightCustomLogin {
        height: 340px;
    }
}

@media (min-height: 860px) {
    .heightCustomLogin {
        height: 380px;
    }
}

.buttonCustom {
    /*width: 300px;*/
    width: 100%;
    /*margin: 20px !important;*/
    border-radius: 20px !important;
    background-color: white !important;
    color: #009DE4 !important;
}

.bodyColorCustom {
    background-color: #009DE4;
}

#txtUsername,
#txtUsername-label {
    color: white !important;
}

#txtPassword,
#txtPassword-label {
    color: white !important;
}

#txtUsername,
#txtPassword {
    text-align: center;
    background-color: transparent !important;
}


/*
.MuiInput-underline:before {
    border-bottom: 1px solid white !important;
}

.MuiInput-focused:before {
    border-bottom: 1px solid white !important;
}*/

.widthLogoStoDomingo {
    width: 100%;
    margin-left: 30px;
}
.widthSliderLogin {
    width: 90%;
}
@media (min-width: 300px) {
    .widthLogoStoDomingo {
        width: 20%;
        margin-left: 30px;
    }
}

@media (min-width: 700px) {
    .widthLogoStoDomingo {
        width: 20%;
    }
}

@media (min-width: 1024px) {
    .widthLogoStoDomingo {
        width: 20%;
    }
}

#divLogin{
    background: white;
    width: 300px;
    min-height: 356px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 11.5px;
}
#idBtnLogin > .MuiButton-label{
    font-family: 'Montserrat-SemiBold' !important;
}
.MuiInput-underline:after {
    border-bottom: 2px solid #808080 !important;
}