#idTabList>.MuiTabs-scroller>.MuiTabs-flexContainer>.MuiButtonBase-root {
    border: 2px solid white !important;
    margin-top: 20px !important;
    margin-left: 20px !important;
    border-radius: 10px 10px 0px 0px !important;
    color: black !important;
    background: white !important;
}

.MuiTabs-indicator {
    background-color: #26559C !important;
}

#idTabList {
    height: 70px !important;
    /*background: #26559C;*/
    border-radius: 10px 10px 0px 0px !important;
}

.iconPDF {
    width: 30%;
    margin-top: 3px;
}

.boxCustomImg {
    border-radius: 5px !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}

/*
.itemTemaCenefa .styleItemChapter {
    color: #fff;
    font-family: 'Montserrat-Semibold';
}

.itemTemaCenefa .styleItemChapter:hover {
    color: #000;
}*/

.button-add-responsive {
    text-align: center;
}

@media (min-width:300px) {
    .button-add-responsive {
        text-align: right;
    }
}

@media (min-width:1000px) {
    .button-add-responsive {
        text-align: center;
    }
}


/** alto dinamico **/

