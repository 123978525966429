.divError{
    margin-right: auto !important;
    margin-left: auto !important;
    width: 210px !important;
    text-align: left;
}
.divError2{
    width: 210px !important;
    text-align: left;
}

.all100x{
    width: 100% !important;
    text-align: left !important;
    margin-bottom: 0px !important;
}
.selectColorError{
    color: #F44336 !important;
    margin-bottom: 0px !important;
    font-size: 0.75rem !important;
    margin-left: 18px !important;
    font-family: 'Montserrat-Regular' !important ;
}

